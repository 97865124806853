/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import EventHelper from "../../../Helpers/event";
import NewApi from "../../../services/new-api";
import "./index.css";
import sendToLogger from "../../../Helpers/errorLogger";
import { useTranslation } from "react-i18next";
import { useZendesk } from "../../../hooks/useZendesk";

export default function Index() {
  const [eventos, setEventos] = useState([]);
  const { t } = useTranslation();
  const { hide } = useZendesk();

  useEffect(() => {
    hide();
    new NewApi()
      .get("events/my")
      .then((res) => setEventos(res.data.data))
      .catch((err) => {
        sendToLogger(err);
      });
  }, []);

  function copyLinkToClipboard(text) {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      Swal.fire({
        icon: "success",
        title: t("CommonExpressions.success"),
        text: t("MyEvents.linkCopied"),
        timer: 2000,
      });
    } catch (err) {
      sendToLogger(err);
      Swal.fire({
        icon: "error",
        title: t("MyEvents.error"),
        text: t("MyEvents.copyLinkError"),
        timer: 2000,
      });
    }

    document.body.removeChild(textArea);
  }

  return (
    <div id="page-myevents" className="container">
      <div className="tickets">
        <h2>{t("MyEvents.myEvents")}</h2>
        {eventos.map((evento) => (
          <div key={evento.id}>
            <div className="myevents-card shadow-sm">
              <div className="myevents-event-info">
                <div className="event-date">
                  <span className="day">{EventHelper.startDay(evento)}</span>
                  <span className="month">
                    {EventHelper.startMonth(evento).toUpperCase()}
                  </span>
                </div>
                <a
                  href={`https://www.uniticket.com.br/eventos/${evento.slug}?code=${evento.steward.code}`}
                >
                  <h5>{evento.name}</h5>
                  <p>
                    <i className="far fa-compass"></i>{" "}
                    {evento.is_online === 1
                      ? "Online"
                      : evento.event_location?.name ||
                        evento.event_location?.formatted_address}
                  </p>
                </a>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <h6>
                    <i className="fas fa-ticket-alt"></i>
                    {t("MyEvents.soldTickets")} {evento.ticket_amount}
                  </h6>
                </div>
                <div className="col-md-4 text-center">
                  <button
                    target="_blank"
                    onClick={() =>
                      copyLinkToClipboard(
                        `https://www.uniticket.com.br/eventos/${evento.slug}?code=${evento.steward.code}`
                      )
                    }
                    rel="noopener noreferrer"
                    class="border-link"
                  >
                    <i className="fas fa-share-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
