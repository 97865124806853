/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import "./index.css";
import { MetaTags } from "react-meta-tags";
import { useZendesk } from "../../../hooks/useZendesk";

export default function Termos() {
  const empresa = process.env.REACT_APP_NAME_EMPRESA || "";
  const { hide } = useZendesk();

  useEffect(() => {
    window.scrollTo(0, 0);
    hide();
  }, []);

  return (
    <>
      <MetaTags>
        <meta name="kdt:page" content="terms-of-use" />
      </MetaTags>
      <div className="container info-containers mt-4 mb-4">
        <div className="row terms">
          <h1>TERMOS DE USO</h1>
          <p>
            Por este instrumento são estabelecidos os termos e as condições
            gerais para a prestação de serviços da Uniticket.
            <br />
            <br />
            Este documento regulamenta as relações entre a Uniticket, os
            Organizadores de Eventos e seus Consumidores e ao acessar os
            recursos tecnológicos disponibilizados pela Uniticket, concordam com
            os termos e condições gerais estabelecidos para a prestação de
            serviços, inclusive suas futuras atualizações.
          </p>

          <h4>
            <strong>1. Definições</strong>
          </h4>

          <p>
            A Uniticket é uma empresa que oferece soluções tecnológicas para
            gestão de Eventos por meio de Plataforma que integra a venda de
            ingressos online e físicos, bem como fornece a gestão de consumo
            através de hardware único que permite a venda de produtos e serviços
            no sistema de fichas ou cashless.
            <br />
            <br />
            Para melhor compreensão deste Termo de Uso, os termos e expressões
            abaixo, quando iniciados em letra maiúscula, no singular ou plural,
            terão os significados que lhes são indicados ao longo deste
            documento ou abaixo:
            <br />
            <br />
            (i) Organizador (“Organizador”): é o produtor do Evento cuja venda
            de ingressos ocorre por meio da Plataforma Uniticket.
            <br />
            (ii) Consumidores (“Consumidores”): são as pessoas que se cadastram
            na Plataforma Uniticket e/ou adquirem ingressos ou reservam lugares
            em Eventos através da Uniticket.
            <br />
            (iii) Usuários (“Usuários”): em caráter geral, qualquer pessoa que
            navegue pela Plataforma Uniticket, aqui inclusos o Organizador e os
            Consumidores.
            <br />
            (iv) Plataforma (“Plataforma”) ou Ferramentas (“Ferramentas”):
            quaisquer mecanismos disponibilizados pela Uniticket para a
            divulgação e comercialização de ingressos online e físicos, bem como
            a gestão de consumo através de hardware único que permite a venda de
            produtos e serviços no sistema de fichas ou cashless.
            <br />
            (v) Evento (“Evento”): show, espetáculo, entretenimento ou qualquer
            outro evento ao vivo produzido pelo Organizador e cadastrado na
            Plataforma Uniticket.
          </p>

          <h4>
            <strong>2. Informações gerais</strong>
          </h4>

          <p>
            2.1. Os serviços oferecidos na Plataforma Uniticket, que consistem
            em: (i) disponibilizar solução tecnológica para que Organizadores
            possam criar, organizar, gerir e divulgar seus próprios Eventos;
            (ii) viabilizar, por meio da Plataforma, as operações de venda de
            ingressos cadastrados por Usuários Organizadores; (iii) hospedar os
            Eventos criados pelos Organizadores; (iv) disponibilizar aos
            Consumidores a compra online de ingressos para os Eventos publicados
            pelos Organizadores no site da Uniticket
            (https://www.uniticket.com.br).
            <br />
            2.2. O(s) Evento(s) criado(s) e divulgado(s) através da Plataforma
            Uniticket é(são) de responsabilidade exclusiva dos próprios
            Organizadores, aos quais competem, exclusivamente, estabelecer as
            condições de venda, realizar e cancelar o(s) Evento(s) ou promover
            outras alterações. A Uniticket não produz ou patrocina qualquer
            Evento, tampouco é criadora, coorganizadora ou proprietária dos
            Eventos hospedados em sua Plataforma. Assim, a Uniticket não possui
            controle sobre as circunstâncias que possam alterar as programações,
            provocar adiamentos ou cancelamentos dos Eventos para os quais os
            ingressos estejam sendo comercializados por meio da Plataforma.
            <br />
            2.3. Toda e qualquer informação ou publicidade referente ao Evento
            que esteja disponível na Plataforma Uniticket constitui conteúdo de
            propriedade e responsabilidade exclusiva dos Organizadores, sendo
            estes, ainda, os responsáveis exclusivos pelo cumprimento da oferta
            veiculada para o Evento divulgado.
            <br />
            2.4. A Uniticket envida seus esforços para a disponibilidade
            contínua e permanente de sua Plataforma. No entanto, poderá ocorrer,
            eventualmente, alguma indisponibilidade temporária decorrente de
            manutenção necessária do sistema ou gerada por motivo de força
            maior, como desastres naturais, falhas ou colapsos nos sistemas
            centrais de comunicação e acesso à internet ou, fatos de terceiro
            que fogem de sua esfera de vigilância e responsabilidade.
            <br />
            2.5. O Consumidor isenta a Uniticket de qualquer dano nos casos de
            paralisações, parciais ou totais, dos seus serviços, decorrentes de
            falta temporária de energia elétrica, de pane nos serviços das
            concessionárias, de falhas nos serviços oferecidos pelas redes
            telefônicas e outros provedores, inclusive provedores de hospedagem,
            de greves, tumultos ou quaisquer outros fatos que não estejam
            diretamente ligados ao serviço. A Uniticket também não será
            responsável por qualquer dano causado por motivo de caso fortuito ou
            força maior, bem como fato de terceiro.
          </p>

          <h4>
            <strong>3. Cadastro de usuários</strong>
          </h4>
          <p>
            3.1. O cadastro na Plataforma Uniticket é gratuito. No entanto, os
            serviços oferecidos serão cobrados de acordo com o estabelecido pela
            Uniticket neste Termo de Uso.
            <br />
            3.2. Para utilização da Plataforma Uniticket o Usuário deverá ser
            cadastrado no site https://www.uniticket.com.br, declarando que suas
            informações de cadastro são verdadeiras e assumindo o compromisso de
            atualizar seus dados sempre que necessário ou requerido.
            <br />
            3.3. A Uniticket poderá negar o cadastro, suspender os serviços ou
            encerrar a conta de qualquer Usuário, especialmente se forem
            identificados indícios de que o cadastro foi preenchido com
            informações incompletas, desatualizadas ou falsas e, ainda, quando a
            conta estiver sendo utilizada de forma indevida ou suspeita.
            <br />
            3.4. A Uniticket não se responsabiliza pela veracidade ou
            assertividade dos dados inseridos no cadastro, sendo que a criação
            de perfis falsos em sua Plataforma poderá ser considerada crime de
            falsa identidade, falsidade ideológica ou estelionato (artigos 307,
            299 e 171, respectivamente, do Código Penal Brasileiro).
            <br />
            3.5. Os Usuários são os únicos responsáveis por eventuais falhas
            e/ou prejuízos decorrentes de cadastros realizados em desacordo com
            este Termo e com as instruções da Plataforma Uniticket. 3.6. Sem
            prejuízo de outras medidas, a Uniticket poderá advertir, suspender
            ou cancelar o cadastro dos Usuários, bem como os Eventos associados,
            negando-se a prestar os serviços descritos neste Termo, caso o
            Usuário:
            <br />
            (i) Descumpra qualquer disposição destes Termos ou qualquer outra
            política da Uniticket;
            <br />
            (ii) Pratique atos fraudulentos ou dolosos;
            <br />
            (iii) Dê causa a qualquer tipo de dano ou prejuízo a terceiro ou à
            própria Uniticket, durante ou em razão da utilização da Plataforma.
          </p>

          <h4>
            <strong>4.Acesso à plataforma {empresa} </strong>
          </h4>
          <p>
            4.1. O Usuário acessará sua conta através de login e senha pessoais
            e intransferíveis e é o único responsável por criar e preservar a
            confidencialidade desses dados, bem como por manter e alterar
            informações pessoais e/ou relativas à sua conta. Eventuais
            alterações em dados cadastrados somente poderão ser feitas pelo
            próprio Usuário, devidamente logado. A Uniticket não possui
            prerrogativa para fazer alterações cadastrais, independentemente da
            razão alegada ou circunstância.
            <br />
            4.2. O Usuário se compromete a não informar a terceiros os dados da
            sua conta na Plataforma Uniticket, responsabilizando-se
            integralmente pelo uso que deles seja feito e se comprometendo a
            notificar a Uniticket, imediatamente, acerca de quaisquer usos não
            autorizados de sua conta ou outras violações de segurança.
            <br />
            4.3. A Uniticket não será responsável pelas perdas e danos
            resultantes de acessos não autorizados ou pelo uso indevido da conta
            do Usuário.
            <br />
            4.4. Apenas o titular da conta na Uniticket poderá ter acesso aos
            dados relativos a ela, tendo em vista seu caráter pessoal.
          </p>

          <h4>
            <strong>5. Utilização da Plataforma pelo Organizador</strong>
          </h4>
          <p>
            5.1. O Organizador que utilizar as Ferramentas da Uniticket deverá
            cadastrar o Evento na Plataforma, onde deverá disponibilizar, no
            mínimo, as seguintes informações, dentre outras que a Uniticket
            poderá exigir, caso a caso: data; local; endereço; horário;
            atrações; idade mínima; conteúdo do Evento; serviços inclusos e/ou
            disponíveis; preços dos ingressos e política comercial (incluindo
            forma de disponibilização; política de devolução do dinheiro em caso
            de cancelamento; valores e quantitativos de ingressos meia-entrada).
            <br />
            5.2. O Organizador é o único responsável pelo Evento e por todas as
            licenças e contratações a ele relativas, inclusive das atrações,
            segurança, fornecedores, patrocinadores, exigências de órgãos
            públicos, dentre outras.
            <br />
            5.3. O Organizador deverá cuidar para não infringir os bons costumes
            e a legislação aplicável, inclusive, mas não se limitando, relativa
            ao direito do Consumidor, patente, marca registrada, direito de
            imagem e/ou direito autoral.
            <br />
            5.4. O Organizador é o único responsável pelos links, atalhos e/ou
            redirecionamentos disponibilizados na página do Evento através da
            Plataforma. A Uniticket não atesta a qualidade, veracidade e
            segurança desses recursos.
            <br />
            5.5. O Organizador deverá informar à Uniticket, imediatamente, sobre
            alterações e/ou cancelamento de Evento(s).
            <br />
            5.6. O Organizador deverá cumprir todas as normas legais, inclusive
            o Código de Defesa do Consumidor e reembolsar os valores pagos pelos
            Consumidores, caso o Evento sofra alteração significativa ou deixe
            de ocorrer, independentemente do motivo.
            <br />
            5.7. O Organizador isenta a Uniticket de responsabilidade pelo(s)
            Evento(s) comercializado(s) através de sua Plataforma. Caso a
            Uniticket venha a sofrer prejuízos decorrentes da ação ou omissão do
            Organizador, este deverá indenizá-la integralmente.
          </p>

          <h4>
            <strong>6. Compra de ingressos</strong>
          </h4>
          <p>
            6.1. Ao concluir a compra de um ingresso, o Consumidor procederá com
            o pagamento, considerando os métodos disponíveis e concluirá a
            compra em ambiente seguro da Plataforma.
            <br />
            6.2. Ao adquirir um ingresso para Evento através da Plataforma da
            Uniticket o Consumidor se compromete a arcar com o pagamento dos
            valores contratados. Identificados casos de má-fé, estará o
            Consumidor sujeito as medidas judiciais cíveis e criminais cabíveis.
            <br />
            6.3. Por segurança, ao realizar uma compra, as seguintes regras se
            aplicam:
            <br />
            (i) Caso o Consumidor forneça dados incorretos, incompletos ou
            desatualizados, a compra poderá não ser confirmada. Nesse caso, o
            Consumidor poderá voltar a fazer uma nova compra, sempre mediante
            condições comerciais e disponibilidade de ingressos ou reservas do
            momento.
            <br />
            (ii) Na hipótese de a data de vencimento da cobrança ser anterior a
            realização do Evento, poderá ocorrer a indisponibilidade do ingresso
            caso o Usuário não realize o pagamento.
            <br />
            (iii) O Consumidor deverá cuidar do ingresso e das informações
            relativas a ele, não permitindo acesso de terceiros e mantendo o
            ingresso guardado em local seguro.
            <br />
            (iv) A Uniticket não se responsabiliza por ingressos adquiridos fora
            dos pontos de venda autorizados.
            <br />
            (v) Questões como alteração de data, restituição de valores nos
            casos previsto por lei e qualquer outro assunto relacionado ao
            Evento são de responsabilidade exclusiva do Organizador.
            <br />
            (vi) A Uniticket não é responsável sobre a realização dos Eventos
            para os quais foram adquiridos ingressos, se limitando a atuar como
            Plataforma para intermediação da venda entre Organizador e
            Consumidores.
            <br />
            (vii) Compete ao Consumidor arcar com a taxa eventualmente cobrada
            pelo Organizador sobre o a comercialização do ingresso (“Taxa de
            Serviço”), assim como eventuais taxas cobradas pela processadora de
            pagamentos para fins de saque do crédito decorrente a estorno, no
            caso de cancelamento da compra pelo Consumidor.
            <br />
            (viii) A Uniticket e as operadoras de cartão de crédito se reservam
            no direito de cancelar a compra realizada pelo Consumidor, gerando o
            cancelamento do ingresso digital ou voucher, nas hipóteses de:
            inconsistência de dados; utilização indevida de cupons promocionais;
            utilização de cartão de crédito de terceiros; utilização de cadastro
            de terceiros; bem como na situações em que a análise de segurança da
            compra pelo sistema antifraude assim o indicar, fato que será
            comunicado ao Consumidor através dos canais de atendimento da
            Uniticket.
            <br />
            (ix) Cabe ao Consumidor verificar o status de sua compra na
            Plataforma Uniticket antes de se dirigir ao Evento, não sendo
            cabível qualquer indenização por danos ou prejuízos nas hipóteses de
            cancelamento da compra decorrentes da análise de segurança. Em caso
            de dúvida, o Consumidor deverá contatar a Uniticket por meio do
            formulário de contato disponível no site.
            <br />
            6.4. Se os compromissos acima forem desrespeitados, a Uniticket
            poderá invalidar os ingressos comprados através de sua Plataforma,
            assim como suspender ou encerrar a conta do Organizador ou do
            Consumidor e se recusar a disponibilizar suas Ferramentas para
            futuras operações.
          </p>

          <h4>
            <strong>7. Taxa de conveniência e emissão de nota fiscal</strong>
          </h4>
          <p>
            7.1. Dependendo do contrato firmado com o Organizado do Evento, a
            Uniticket poderá realizar a cobrança de uma Taxa de Serviço (“Taxa
            de Conveniência”) pelo uso da Plataforma, calculada a partir das
            vendas processadas por meio dela. A Taxa de Serviço cobrada poderá,
            por opção do Organizador ser repassada para o Consumidor de modo que
            será incluída no preço total a ser pago pelo ingresso, podendo
            variar conforme a complexidade de cada Evento.
            <br />
            7.2. A Taxa de Conveniência será definida a critério da Uniticket,
            servindo apenas para cobrir os custos do serviço prestado e guardará
            o mais perfeito equilíbrio entre o preço do ingresso e a
            conveniência disponibilizada ao Consumidor.
            <br />
            7.3. A referida taxa, quando incidente, será informada no ato da
            compra destacada do valor de face do ingresso.
            <br />
            7.4. Ao aceitar o presente Termo e concluir a compra de um ingresso
            por meio da Plataforma, o Consumidor declara que foi informado
            prévia e adequadamente acerca da Taxa de Conveniência, concordando
            com a transferência de custos.
            <br />
            7.5. Caso o Consumidor prefira adquirir seus ingressos sem o
            pagamento da Taxa de Conveniência, poderá optar por realizar a
            compra do ingresso diretamente na bilheteria do Evento ou no ponto
            de venda (Praça Menelick de Carvalho, nº 150, Juiz de Fora/MG, CEP:
            36015-330 ou outro, quando expressamente indicado na descrição do
            Evento).
            <br />
            7.6. Os valores dos ingressos adquiridos pelos Consumidores através
            da Plataforma Uniticket são repassados ao Organizador, que é o único
            responsável pela execução do Evento conforme ofertado, pelo
            pagamento de tributos e pelo cumprimento das obrigações legais
            relacionadas ao Evento.
            <br />
            7.6. A Uniticket emitirá a correspondente nota fiscal para cada
            Consumidor após a conclusão de transações realizadas por meio da
            Plataforma.
          </p>

          <h4>
            <strong>8. Vendas de Ingressos e pagamentos</strong>
          </h4>
          <p>
            8.1. O processamento das compras e seus respectivos pagamentos
            realizados através da Plataforma Uniticket terão início na página do
            Evento, que é alimentada por seu Organizador. Na página, o
            Consumidor informa os ingressos que pretende comprar, aponta o
            método de pagamento que pretende utilizar, dentre os
            disponibilizados pela Uniticket, e finaliza sua compra. Tão logo o
            intermediário financeiro confirme o pagamento à Uniticket, esta irá
            realizar os procedimentos internos de confirmação e, após, repassar
            ao Organizador o valor que lhe couber, deduzida a Taxa de Serviço.
            <br />
            8.2. Qualquer operação que envolver compra, pagamento e/ou repasse
            de recursos, poderá passar por processo interno de validação pela
            Uniticket.
            <br />
            8.3. Os ingressos são comercializados pelo período determinado pelo
            Organizador, não competindo a Uniticket alterar lotes e prazos de
            venda.
            <br />
            8.4. Os ingressos estão sujeitos a esgotamento de acordo com a
            repercussão do Evento, capacidade do local e determinação do
            Organizador.
          </p>

          <h4>
            <strong>9. Entrega e utilização de ingressos</strong>
          </h4>
          <p>
            9.1. Os ingressos comprados através da Plataforma Uniticket serão
            enviados para o e-mail cadastrado pelo Consumidor tão logo o
            pagamento seja aprovado. Pagamentos aprovados em tempo real
            habilitam que os ingressos também sejam baixados na própria tela de
            confirmação da compra.
            <br />
            9.2. Cabe ao Consumidor garantir que seus sistemas de antisspam,
            filtros ou similares não interfiram no recebimento dos comunicados e
            materiais da Uniticket, não sendo aceitável nenhuma escusa caso não
            tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude
            dos recursos mencionados, o que não gerará qualquer tipo de
            expectativa de direito ou indenização par o Consumidor.
            <br />
            9.3. Além da entrega por meio de mensagem eletrônica, os ingressos
            também poderão ser acessados na área autenticada da Plataforma (por
            meio de login e senha). O ingresso poderá ser acessado na área “Meus
            Ingressos” do site.
            <br />
            9.4. Os ingressos são associados a um código alfanumérico único,
            representado em algarismos, “QR Code” e Código de Barras
            (exclusivamente no formato PDF). Após o recebimento, caberá ao
            Consumidor mantê-los em segurança, não divulgando-os publicamente,
            sobretudo em redes sociais, nem permitindo o acesso de terceiros a
            eles.
            <br />
            9.5. Para entrada efetiva no Evento o Consumidor deverá apresentar o
            ingresso impresso ou, se viabilizado pelo Organizador, por meio
            digital (na hipótese de leitura por “QR Code”) não sendo suficiente
            a apresentação de informações como login, senha ou confirmação de
            pedido.
            <br />
            9.6. Os ingressos comprados através da Plataforma Uniticket são
            nominais, cabendo ao Organizador decidir pela conferência dessa
            informação no momento de acesso ao Evento. A não conferência dessa
            informação não gerará qualquer tipo de expectativa de direito ou
            indenização.
            <br />
            9.7. Os ingressos adquiridos na Plataforma Uniticket podem ser
            usados para acesso ao Evento uma única vez. O check-in do
            participante, ou seja, a leitura e validação do ingresso do
            Consumidor, através do identificador único é de responsabilidade do
            Organizador. O acesso do participante ao Evento não será liberado
            caso o check-in do ingresso já tenha sido realizado anteriormente,
            salvo por decisão exclusiva do Organizador.
            <br />
            9.8. A 2ª via de ingresso digital pode ser impressa a qualquer
            momento pelo Consumidor na “área do cliente” na Plataforma
            Uniticket.
          </p>

          <h4>
            <strong>10. Transferência dos ingressos</strong>
          </h4>
          <p>
            10.1. O Consumidor, titular da compra, poderá solicitar a
            transferência de ingressos para outro Usuário.
            <br />
            10.2. Nessa hipótese os dados associados ao Consumidor, titular da
            compra, não podem ser alterados, sendo modificado apenas o nome de
            quem vai utilizar efetivamente o ingresso.
            <br />
            10.3. A alteração nominal do ingresso deverá ser solicitada pelo
            Consumidor titular por e-mail, através do endereço
            atendimento@uniticket.com.br.
            <br />
            10.4. A referida troca poderá ser realizada apenas 01 (uma) vez, com
            até 48 (quarenta e oito) horas de antecedência do horário previsto
            para início do Evento, ou outro prazo, se descrito na página do
            Evento.
            <br />
            10.5. Ao receber o ingresso via troca nominal, o terceiro que recebe
            o ingresso declara-se ciente de que o Consumidor titular da compra
            poderá, a qualquer momento, e desde que autorizado pelo Organizador,
            cancelar a compra ou efetuar uma nova alteração, inviabilizando,
            assim, seu acesso ao Evento.
            <br />
            10.6. Ao receber o ingresso via alteração nominal, o terceiro que
            recebeu o ingresso será considerado corresponsável pela compra. Caso
            seja comprovada fraude ou outra ilegalidade na transação, poderá ser
            responsabilizado e estará sujeito a indenizar pelo dano causado.
            <br />
            10.7. A Uniticket se resguarda no direito de negar a alteração
            nominal do ingresso quando houver suspeita de fraude, dispensada a
            comprovação da fraude.
          </p>

          <h4>
            <strong>
              11. Desistência, cancelamento e reembolso de transações
            </strong>
          </h4>
          <p>
            11.1. O Consumidor poderá exercer o direito de arrependimento da
            compra no prazo de até 7 (sete) dias corridos, contados da data da
            compra, desde que a solicitação seja formulada em até 48 (quarenta e
            oito) horas úteis antes do início do Evento.
            <br />
            11.2. O cancelamento da compra deverá ser formalizado por escrito
            através da central de atendimento acessível pela seção Fale Conosco
            na Plataforma Uniticket.
            <br />
            11.3. Em caso de compras por meio de cartão de crédito, o reembolso
            do Consumidor ocorrerá por crédito no próprio cartão utilizado para
            a compra. Na hipótese de pagamento por meio de boleto bancário, o
            reembolso será realizado por depósito bancário, no prazo de até 20
            (vinte) dias úteis, contados da data da solicitação de cancelamento
            que contenha os dados bancários completos para efetivação da
            devolução.
            <br />
            11.4. O Consumidor que se recusar a realizar o procedimento de
            cancelamento conforme as instruções deste Termo e políticas da
            Plataforma, ou, não fornecer os dados bancários para estorno, não
            poderá reclamar qualquer responsabilização da Uniticket pelo não
            atendimento da solicitação de devolução, que estará inviabilizada
            por sua culpa exclusiva.
            <br />
            11.5. Todas as reclamações e pedidos de desistência realizados com
            antecedência inferior a 48 (quarenta e oito) horas úteis da data do
            Evento ou após a realização do Evento, deverão ser encaminhados
            diretamente para o Organizador, não competindo a Uniticket a
            apreciação do pedido em desacordo com a sua política de
            cancelamento.
            <br />
            11.4. Se identificado que algum Evento não foi executado ou foi
            realizado de maneira distinta ao anunciado, a Uniticket reserva-se o
            direito de suspender, provisoriamente, o valor do repasse para o
            Organizador ou, se for o caso, reembolsar todas as compras
            efetuadas, até que a situação esteja esclarecida e regularizada.
            <br />
            11.5. Especificamente em casos de compras com indícios de fraude, a
            Uniticket poderá solicitar ao Consumidor a cópia digitalizada dos
            documentos do titular do cartão de crédito utilizado para pagamento,
            bem como do próprio cartão bancário utilizado, visando legitimá-las.
            Neste caso, o contato será feito através dos nossos canais oficiais
            de atendimento. O prazo para envio da documentação será estabelecido
            pela Uniticket, de acordo com a data e horário em que o Evento
            ocorrerá, de forma que, remetido o documento pelo Consumidor,
            ocorrerá a análise e o retorno através do mesmo e-mail utilizado
            para a compra, ou outro canal de contato oficial. Caso os documentos
            não sejam enviados pelo Consumidor no prazo determinado, ficará a
            cargo da Uniticket decidir por cancelar, suspender, ou não, a
            transação, a seu exclusivo critério.
            <br />
            11.6. Na ocorrência de impedimento à realização do Evento, ligado a
            caso fortuito (como por exemplo, guerra, motim, dentre outros) ou
            força maior (como fenômenos da natureza, epidemias, pandemias dentre
            outros), o Organizador deverá cancelar o Evento e realizar a
            devolução dos valores para os Consumidores, se de outra forma não
            dispuser a Lei.
          </p>

          <h4>
            <strong>12. Contestações e estornos</strong>
          </h4>
          <p>
            12.1. A Uniticket adotará as medidas necessárias para evitar que
            ocorram fraudes nos procedimentos e compras por meio da Plataforma.
            <br />
            12.2. As contestações reportadas serão analisadas pela operadora de
            cartão de crédito, por nossos processadores de pagamentos ou pela
            própria Uniticket.
            <br />
            12.3. Tendo em vista os investimentos que faz e as normas e
            procedimentos de segurança que a Uniticket adota a fim de evitar a
            ocorrência de fraudes, em nenhuma hipótese a Uniticket poderá ser
            responsabilizada por prejuízos causados por terceiros.
            <br />
            12.4. Eventuais estornos ocorridos nas vendas realizadas por meio da
            Plataforma são responsabilidade do Organizador, independentemente da
            razão da contestação.
            <br />
            12.5. A Uniticket somente conduzirá as disputas abertas pelos
            Consumidores, tais como, mas não somente, chargebacks
            (contestações), reclamações diversas, cancelamento e estornos sem a
            intervenção ou aprovação do Organizador, nos casos de:
            <br />
            (i) não cumprimento da política de reembolsos do Evento e/ou do
            Código de Defesa do Consumidor, por parte do Organizador;
            <br />
            (ii) detecção de indícios de fraude em compras realizadas, estejam
            elas pendentes de aprovação ou já aprovadas, independentemente se os
            riscos são provenientes do Consumidor ou do Organizador;
            <br />
            (iii) cancelamento do Evento;
            <br />
            (iv) erro técnico no processamento da transação;
            <br />
            (v) constatação de número elevado de queixas referentes a um
            determinado Evento, por parte dos Consumidores.
          </p>

          <h4>
            <strong>13. Responsabilidade da {empresa}</strong>
          </h4>
          <p>
            13.1. A responsabilidade da Uniticket é limitada exclusivamente aos
            serviços por ela prestados, quais sejam, os serviços de
            intermediação de ingressos via Plataforma com a emissão de ingressos
            digital.
            <br />
            13.2. A Uniticket não é Organizadora de Eventos, portanto, não tem
            qualquer tipo de vínculo ou sociedade com o Organizador de Evento.
            De tal modo não redige suas publicidades, não contrata atrações ou
            serviços bem como não tem acesso aos Eventos.
            <br />
            13.3. Por se tratar de Plataforma de mediação de pagamentos e
            ingressos, a responsabilidade da Uniticket é regida pela Lei nº
            12.965/14 (Marco Civil da Internet), a qual limita sua
            responsabilidade aos serviços e informações que efetivamente presta
            ao Consumidor/Usuário, não podendo responder por danos decorrentes
            das informações ou publicidade do Organizador, ainda que inseridas
            na Plataforma, bem como por danos decorrentes do Evento em si, tais
            como interrupção, cancelamento, mudanças de local, data ou horário,
            substituição de artistas, não prestação dos serviços anunciados,
            danos civis ou morais aos Usuários, entre outros.
          </p>

          <h4>
            <strong>14. Responsabilidade do Organizador</strong>
          </h4>
          <p>
            14.1. Ao realizar o cadastro do Evento na Plataforma, o Organizador
            declara ser o titular de todos os direitos referentes ao Evento
            inclusive direitos autorais.
            <br />
            14.2. O Organizador declara ainda ser o legítimo titular do Evento
            publicado, respondendo por todos os atos e/ou danos decorrentes do
            Evento anunciado na Plataforma da Uniticket.
            <br />
            14.3. Toda a informação disponibilizada, publicidade, vídeo ou
            banner referente ao Evento divulgado na Plataforma Uniticket
            constitui conteúdo de responsabilidade exclusiva do Organizador, não
            tendo a Uniticket qualquer ingerência sobre o conteúdo de informação
            ou publicidade do Evento ofertado ou sua insuficiência de
            informações, cabendo única a exclusivamente ao Organizador do Evento
            a responsabilização nos termos dos arts. 12 e 14 do Código de Defesa
            do Consumidor.
            <br />
            14.4. A venda de ingressos com o benefício da modalidade
            meia-entrada constitui responsabilidade do Organizador do Evento,
            nos termos da legislação da meia-entrada vigente. A Uniticket por
            sua vez, disponibiliza aos Consumidores página específica em sua
            Plataforma com todas as informações sobre a Lei Federal nº 12.852,
            de 05 de agosto de 2013, Lei 12.933, de 26 de dezembro de 2013 e
            Decreto Federal 8.537, de 05 de outubro de 2015. Eventuais
            descumprimentos por parte do Organizador podem ser comunicados à
            Uniticket através dos canais de atendimento ao Consumidor.
            <br />
            14.5 O Organizador se obriga a oferecer suporte aos Consumidores
            após a realização do Evento, e se responsabiliza por quaisquer
            estornos, reembolsos ou cobranças indevidas.
          </p>

          <h4>
            <strong>15. Responsabilidades e obrigações dos Consumidores</strong>
          </h4>
          <p>
            15.1. Os Consumidores se obrigam a utilizar a Plataforma Uniticket
            de maneira ética e adequada aos propósitos estabelecidos neste
            Termo, atendendo ao disposto nas leis brasileiras, comprometendo-se
            a se absterem de qualquer atividade que constitua violação das
            referidas normas.
            <br />
            15.2. Ao utilizarem a Plataforma Uniticket e suas funcionalidades,
            os Consumidores se obrigam, dentre outras obrigações descritas neste
            Termo, a:
            <br />
            (i) Inserir dados corretos, completos e atualizados na aquisição
            (onerosa ou gratuita) dos ingressos. Caso contrário, a aquisição
            poderá ser invalidada e o Consumidor, caso queira, terá que fazer
            uma nova aquisição, estando sujeito às condições e disponibilidade
            de ingressos, inscrições ou contribuições nesse segundo momento;
            <br />
            (ii) Arcar com os valores cobrados na fatura de cartão de crédito,
            caso essa seja a forma de pagamento escolhida pelo Consumidor. A
            cobrança poderá vir em nome da Uniticket, ou de algum de nossos
            processadores de pagamentos;
            <br />
            (iii) Arcar com o valor total e integral do boleto e pagá-lo até a
            data do seu vencimento, caso o Consumidor tenha optado por esse
            método de pagamento. Após a data limite de pagamento, o boleto deixa
            de ser válido para a compra e o ingresso não estará mais reservado
            para o Consumidor.
            <br />
            (iv) Comprar os ingressos apenas através da Plataforma Uniticket, ou
            em pontos de venda autorizados. A Uniticket não se responsabiliza e
            não atesta a veracidade ou confiabilidade de ingressos adquiridos de
            terceiros, outros sites ou qualquer ponto de venda não autorizado,
            ficando o Consumidor ciente que ao fazer tal aquisição correrá o
            risco de ser vítima de esquema de fraude ou engenharia social;
            <br />
            (v) Participar do processo de apuração de eventuais fraudes nas
            transações efetuadas, realizado por meio de sistema de análise de
            risco da Uniticket e de seus parceiros. A análise de risco da
            transação é feita com base em padrões históricos, conferência dos
            dados cadastrais e bases externas, podendo resultar numa
            probabilidade de que determinada transação seja fraudulenta. Feita a
            avaliação de risco transacional, a compra poderá ser aprovada ou
            não, segundo critérios pré-estabelecidos. A não aprovação não gerará
            expectativa de direito, tampouco indenização de qualquer espécie;
            <br />
            (vi) Fornecer todas as informações e documentos que forem
            solicitados através dos canais oficiais da Uniticket, caso seja
            necessária alguma validação da compra por parte da Uniticket ou de
            nossos processadores de pagamentos.
            <br />
            (vii) Apresentar, sempre que requerido, na portaria do Evento, um
            documento de identidade com a foto do titular do cartão e/ou o
            cartão utilizado na compra, caso tenha adquirido ingressos via
            cartão de crédito, sob pena de ter o direito de acesso ao Evento
            negado, sem que tal fato gere direito a indenização de qualquer
            espécie;
            <br />
            (viii) Isentar a Uniticket de qualquer responsabilidade sobre a
            realização dos Eventos para os quais foram adquiridos ingressos. A
            Uniticket não é Organizadora de Eventos e apenas fornece a
            Plataforma para que os Organizadores vendam seus ingressos e
            gerenciem seus clientes.
            <br />
            15.3. Em todos os casos, a Uniticket, a seu exclusivo critério,
            poderá ainda se recusar a prestar quaisquer serviços,
            independentemente da motivação, sem que isso constitua limitação de
            direitos.
          </p>

          <h4>
            <strong>16. Direitos de Propriedade Intelectual</strong>
          </h4>
          <p>
            16.1. O uso comercial da expressão "Uniticket" como marca, nome
            empresarial ou nome de domínio, bem como os conteúdos das telas
            relativas aos serviços da Uniticket, assim como os programas, “look
            and feel” dos sites, bancos de dados, redes, arquivos que permitem
            ao Usuário acessar e usar a sua conta, são de titularidade da
            Uniticket e estão protegidos pelas leis e tratados internacionais de
            direito autoral, marcas, patentes, modelos e desenhos industriais. O
            uso indevido e a reprodução total ou parcial dos referidos conteúdos
            são proibidos, salvo a autorização prévia e expressa por escrito da
            Uniticket.
            <br />
            16.2. As páginas dos Eventos poderão conter links para outros sites,
            o que não significa que esses sites sejam de propriedade ou operados
            pela Uniticket, que não possui controle sobre esses sites de
            terceiros, razão pela qual não será responsável pelos seus
            conteúdos, práticas e serviços ofertados. A presença de links para
            outros sites não implica relação de sociedade, de supervisão, de
            cumplicidade ou solidariedade da Uniticket para com esses sites e
            seus conteúdos.
          </p>

          <h4>
            <strong>17. Privacidade dos Usuários</strong>
          </h4>
          <p>
            17.1. A Uniticket possui documento próprio, denominado “Política de
            Privacidade”, que regula o tratamento dado às informações e dados
            pessoais coletados através da Plataforma Uniticket e suas
            funcionalidades.
            <br />
            17.2. A “Política de Privacidade” é parte integrante e inseparável
            do Termo de Uso da Plataforma Uniticket e pode ser acessada no link
            encontrado em seu rodapé.
            <br />
            17.3. Caso alguma disposição da “Política de Privacidade” conflite
            com qualquer outra do presente documento, deverá prevalecer o
            descrito na norma mais específica.
          </p>

          <h4>
            <strong>
              18. Proteção de registros, informações e comunicações privadas
            </strong>
          </h4>
          <p>
            18.1. A Uniticket somente será obrigada a disponibilizar registros
            de acesso, informações pessoais ou comunicações privadas armazenadas
            em sua Plataforma mediante ordem judicial ou requisição de
            autoridade policial ou administrativa competente.
            <br />
            18.2. Em todos os casos, a Uniticket comunicará o Organizador sobre
            a existência do pedido de revelação, ficando desde já autorizada a
            responder a requisição recebida.
            <br />
            18.3. Visando a proteção dos direitos dos Usuários e de terceiros,
            caso ocorra a tentativa ou a efetiva utilização indevida dos
            serviços da Plataforma, a Uniticket poderá indicar os dados do
            Usuário às autoridades públicas, aos serviços de proteção ao
            crédito, dentre outros, para início dos procedimentos legais e
            administrativos cabíveis.
          </p>

          <h4>
            <strong>19. Limitação da responsabilidade da {empresa}</strong>
          </h4>
          <p>
            19.1. Fica expressamente estabelecido que a Uniticket não tem
            obrigação de controlar todas as ações executadas pelos Usuários no
            uso da Plataforma Uniticket e, por conseguinte, esta não poderá ser
            responsabilizada pelos atos de seus Usuários, inclusive aqueles de
            caráter ilícito, imoral ou antiéticos porventura perpetrados,
            cabendo à estes responderem pessoal e exclusivamente por eventuais
            reclamações de terceiros ou demandas judiciais, devendo manter a
            Uniticket livre de qualquer responsabilidade ou ônus.
            <br />
            19.2. A Uniticket não produz e não é responsável por qualquer
            Evento. A responsabilidade da Uniticket se limita a disponibilizar
            meios para que os Organizadores de Eventos comercializem ingressos.
            <br />
            19.3. A Uniticket não se responsabiliza por qualquer dano, direto ou
            indireto, ocasionado por eventos de terceiros, como ataque de
            hackers, falhas no sistema, no servidor ou na conexão à internet,
            inclusive por ações de programas maliciosos como vírus, cavalos de
            troia, e outros que possam, de algum modo, danificar o equipamento
            ou a conexão dos Usuários em decorrência do acesso, utilização ou
            navegação na Plataforma Uniticket, salvo se tal fato decorrer de
            dolo ou culpa da Uniticket.
            <br />
            19.4. A Plataforma Uniticket, suas funcionalidades ou parte delas
            poderá ficar indisponível devido a atualizações do sistema ou outros
            fatores externos. Na medida em que os Usuários escolhem e aceitam
            utilizar a Plataforma, o fazem por iniciativa própria concordando
            com a possibilidade de as Ferramentas poderem ficar temporariamente
            indisponíveis.
            <br />
            19.5. Os Usuários concordam que a Uniticket, seus sócios,
            funcionários e prepostos não são responsáveis, em nenhuma hipótese,
            por reparar quaisquer prejuízos, materiais ou morais, relacionados à
            utilização da Plataforma.
          </p>

          <h4>
            <strong>20. Disposições gerais</strong>
          </h4>
          <p>
            20.1. A Uniticket não se responsabiliza por qualquer dano ou
            prejuízo com envio de informações ou de conteúdo publicitário que
            não tenham sido disparados pela própria Uniticket, e não se
            responsabiliza pelo conteúdo das opiniões postadas na página dos
            Eventos.
            <br />
            20.2. O Usuário declara ter ciência de que a Uniticket não é
            Organizadora de Eventos, mas tão somente Plataforma de mediação de
            pagamentos para aquisição dos ingressos de Eventos dos
            Organizadores. Eventuais reclamações ou ações judiciais decorrentes
            de fatos do Evento deverão ser direcionadas exclusivamente ao
            Organizador.
            <br />
            20.3. Ao navegar pela Plataforma Uniticket e utilizar suas
            funcionalidades, os Usuários aceitam todo o disposto nos Termos de
            Uso e demais políticas legais que se encontram vigentes na data de
            acesso. Por isso, é recomendável que os Usuários se mantenham
            atualizados.
            <br />
            20.4. Nossos serviços são direcionados para pessoas maiores de 18
            (dezoito) anos, com documento pessoal e endereço de e-mail válidos,
            ou ainda, para menores e incapazes, desde que devidamente
            representados ou assistidos por seus representantes legais.
            <br />
            20.5. As regras e condições de uso ora apresentadas poderão ser
            alteradas a qualquer tempo sem necessidade de comunicação prévia da
            Uniticket. Ao realizar o cadastro na Plataforma Uniticket o
            Consumidor estará concordando expressamente com as regras e
            condições de utilização ora apresentadas, nos termos da legislação
            vigente.
            <br />
            20.6. A Uniticket se reserva o direito de bloquear o cadastro do
            Consumidor e impedir usos futuros, cancelar a compra de ingressos
            conforme análise de segurança, bem como tomar as medidas judiciais
            cabíveis, na hipótese de violação das regras e condições
            explicitadas neste contrato, não sendo cabível qualquer pedido de
            indenização em decorrência do cancelamento do cadastro.
            <br />
            20.7. A eventual tolerância quanto a qualquer violação dos termos e
            condições será considerada mera liberalidade e não será interpretada
            como novação, precedente invocável, renúncia a direitos, alteração
            tácita dos termos ou direito adquirido.
            <br />
            20.8. Caso alguma disposição deste Termo de Uso for julgada
            inaplicável ou sem efeito, o restante das normas continua a viger,
            sem a necessidade de medida judicial que declare tal assertiva. Os
            Termos aqui descritos serão interpretados segundo a legislação
            brasileira.
            <br />
            20.9. A comunicação entre a Uniticket e o Usuário deverá ser
            realizada pelos canais de atendimento indicados e disponibilizados
            na Plataforma Uniticket.
            <br />
            20.10. Sempre que possível, potenciais divergências entre o Usuário
            e a Uniticket serão resolvidas de forma amigável. Quando todos os
            esforços neste sentido forem esgotados, você concorda, desde já, que
            fica eleito o foro da comarca de Juiz de Fora, Minas Gerais, para
            resolver controvérsias ou queixas oriundas da utilização de nossa
            Plataforma ou relacionadas aos Termos de Uso.
          </p>
        </div>
      </div>
    </>
  );
}
