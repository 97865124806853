import React, { useEffect, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

export function CreateEventButton() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openNewTab = () => {
    const url = process.env.REACT_APP_CREATE_EVENT_URL || 'https://materiais.venderingresso.com.br/produtor';
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <button
      className="event-button"
      style={isMobile ? { width: "100%" } : { width: "auto" }}
      onClick={openNewTab}
    >
      <span className="event-button-text">
        {t("CommonExpressions.createYourEvent")}
      </span>
    </button>
  );
}
